<template>
  <div class="qr-code">
    <div class="qr-code__border left-top" />
    <div class="qr-code__border right-top" />
    <div class="qr-code__border right-bottom" />
    <div class="qr-code__border left-bottom" />

    <svg
      v-if="status === 'expired'"
      width="130" height="130"
      viewBox="0 0 130 130" fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M32.5 0H65V32.5L32.5 65H0V32.5L32.5 0Z" fill="#DA2828" fill-opacity="0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M65 65H32.5L0 97.5V130H32.5L65 97.5V130H97.5L130 97.5V65H97.5L130 32.5V0H97.5L65 32.5V65ZM65 65H97.5L65 97.5V65Z" fill="#DA2828" fill-opacity="0.2"/>
      <g clip-path="url(#clip0_587_4922)">
      <path d="M97.5 39.9481C97.5 35.8395 94.1605 32.5 90.0519 32.5H39.9481C35.8395 32.5 32.5 35.8395 32.5 39.9481V90.0519C32.5 94.1605 35.8395 97.5 39.9481 97.5H90.0519C94.1605 97.5 97.5 94.1605 97.5 90.0519V39.9481Z" fill="#DA2828"/>
      <path d="M52 52L78 78M78 52L52 78" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_587_4922">
      <rect width="65" height="65" fill="white" transform="translate(32.5 32.5)"/>
      </clipPath>
      </defs>
    </svg>

    <svg
      v-else-if="status === 'payed'"
      width="130" height="130"
      viewBox="0 0 130 130" fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M32.5 0H65V32.5L32.5 65H0V32.5L32.5 0Z" fill="#2F57E9" fill-opacity="0.2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M65 65H32.5L0 97.5V130H32.5L65 97.5V130H97.5L130 97.5V65H97.5L130 32.5V0H97.5L65 32.5V65ZM65 65H97.5L65 97.5V65Z" fill="#2F57E9" fill-opacity="0.2"/>
      <g clip-path="url(#clip0_192_4519)">
      <path d="M97.5 39.9481C97.5 35.8395 94.1605 32.5 90.0519 32.5H39.9481C35.8395 32.5 32.5 35.8395 32.5 39.9481V90.0519C32.5 94.1605 35.8395 97.5 39.9481 97.5H90.0519C94.1605 97.5 97.5 94.1605 97.5 90.0519V39.9481Z" fill="#2F57E9"/>
      <path d="M81.4723 54.2835C82.531 55.3422 82.531 57.0536 81.4723 58.1129L63.8679 75.7167C63.3398 76.2449 62.6465 76.5107 61.9532 76.5107C61.2599 76.5107 60.5667 76.2449 60.0385 75.7167L51.2366 66.9148C50.1773 65.856 50.1773 64.1441 51.2366 63.0854C52.2954 62.0261 54.0067 62.0261 55.066 63.0854L61.9532 69.9726L77.6428 54.2835C78.7016 53.2242 80.413 53.2242 81.4723 54.2835Z" fill="#D3FF53"/>
      </g>
      <defs>
      <clipPath id="clip0_192_4519">
      <rect width="65" height="65" fill="white" transform="translate(32.5 32.5)"/>
      </clipPath>
      </defs>
    </svg>

    <QrcodeVue
      v-else-if="value"
      :value="value"
      :size="160"
      level="H"
    />
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    QrcodeVue
  },
  props: {
    status: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: 'OnliX'
    }
  }
}
</script>

<style lang="scss" scoped>
.qr-code {
  position: relative;
  max-width: 200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 20px;

  .qr-code__border {
    position: absolute;
    height: 50px;
    width: 50px;

    &.left-top {
      left: 0;
      top: 0;
      border-left: 1px solid #787C8D;
      border-top: 1px solid #787C8D;
    }

    &.right-top {
      right: 0;
      top: 0;
      border-right: 1px solid #787C8D;
      border-top: 1px solid #787C8D;
    }

    &.right-bottom {
      right: 0;
      bottom: 0;
      border-right: 1px solid #787C8D;
      border-bottom: 1px solid #787C8D;
    }

    &.left-bottom {
      left: 0;
      bottom: 0;
      border-left: 1px solid #787C8D;
      border-bottom: 1px solid #787C8D;
    }
  }

  svg {
    width: 130px;
    height: 130px;
  }
}
</style>
